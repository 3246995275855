import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Text } from "../../components";

const Wrapper = styled.footer`
  padding: 112px 0;
  background: #27494a;
  display: flex;
  flex-flow: row wrap;

  ${media.lessThan("medium")`
    padding: 60px 0;

    .medias {
      margin-right: auto;
      margin-top: 60px;
    }

    .logo-container {
      margin: auto;
    }
  `}
`;

const Main = styled.main`
  width: 90%;
  max-width: 1630px;
  margin: 0 auto;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

const Column = styled.div``;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #548183;
  margin: 100px 0;

  ${media.lessThan("medium")`
    margin: 60px 0;
  `}
`;

const MediaLink = styled.a`
  :not(:last-of-type) {
    margin-right: 32px;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Main>
        <Row>
          <Column className="logo-container">
            <StaticImage src="../../images/svgs/origgem-white.svg" alt="Origgem" width={251} height={82} />
          </Column>
          <Column style={{ marginLeft: "auto" }} className="medias">
            <MediaLink href="https://www.facebook.com/origgemcaxias" alt="Facebook" title="Facebook" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={["fab", "facebook-f"]} color="white" size="1x" />
            </MediaLink>
            <MediaLink href="https://www.instagram.com/origgem_/" alt="Instagram" title="Instagram" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={["fab", "instagram"]} color="white" size="1x" />
            </MediaLink>
            <MediaLink href="https://wa.me/555491286309" alt="Whatsapp" title="Whatsapp" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={["fab", "whatsapp"]} color="white" size="1x" />
            </MediaLink>
          </Column>
        </Row>
        <Line />
        <Row style={{ justifyContent: "space-between" }}>
          <Column>
            <Text color="white">Rua Atílio Andreazza 3112, Sagrada Família - Caxias do Sul/RS</Text>
          </Column>
          <Column>
            <Text
              color="white"
              as="a"
              href="mailto:origgem@origgemserniorhouse.com.br"
              style={{ textDecoration: "none" }}
            >
              origgem@origgemserniorhouse.com.br
            </Text>
          </Column>
          <Column>
            <Text color="white">+55 54 99134.2696</Text>
          </Column>
        </Row>
      </Main>
    </Wrapper>
  );
};

export default Footer;
